<template>
<div>
  <!-- <div class="banner" style="background-image: url('/images/orange-bg.jpg'); background-size: contain; background-repeat: x-repeat;"> -->
  <!-- </div> -->
  <div class="pricing">
    
    <div class="container">
      <center>
        
        <h3 class="mt-4 mb-3 green-text d-inline-block">We are always <span style="font-weight: 600;">FREE</span> for freelancers! </h3>
        <el-button type="success" class="ml-3 d-inline-block bold" style="margin-bottom: 10px; vertical-align: middle;" v-scroll-to="{ element: '#freelancers',  duration: 1500, offset: -100 }" size="small">Learn more</el-button>
        <img src="images/green-divider.png" width="90" height="auto" class="mt-4 mb-4 divider d-block">
      </center>
      <div class="mt-3 toggle-btns price">
        <center>
          <h1 class="bold orange-text">Our Employer Plans</h1>
          <h6 class="mb-4">Start for free and upgrade when you're ready.</h6>
        </center>
        <ul class="p-0 mb-5 text-center tab">
          <li class="mr-3">
            <input id="monthly" 
                  type="radio" 
                  name="frequency" 
                  value="monthly" 
                  v-model="frequency"
                  required/>
            <label for="monthly" style="margin-left: -6px;" class="employer-btn">Monthly</label>
          </li>
          <li>
            <input id="yearly" 
                  type="radio" 
                  name="frequency" 
                  value="yearly" 
                  v-model="frequency"
                  required/>
            <label for="yearly">Yearly</label>
          </li>
        </ul>
      </div>
      <!-- <div class="mx-auto mb-5 offer">
        <p class="text-center small"><span class="bold">Great news!</span> We are offering <span class="bold">75% off our Silver plan</span> to the next 500 employers!</p>  
      </div> -->
      <div class="flex row justify-content-center">
        
        <div class="mb-4 col-lg-3 col-md-6 col-sm-6 col-xs-12 employer-trial">
          <el-card class="pricing-card" body-style="padding: 30px 0 0 0;" >
            <h5 class="text-center">Employer</h5>
            <h5 class="text-center title">Trial</h5>
            <div class="price-banner">
              <h3 class="m-0">Free</h3>
            </div>
            <ul>
              <li>Unlimited time</li>
              <li>1 job post</li>
              <li>Up to 24-72 hours for approval</li>
              <li class="mb-4">Bookmark freelancers </li>
            </ul>
            <div class="clearfix bottom">
              <!-- <el-button type="text" class="button"
                         @click="loginModal = true;
                         selected = 'basic'">Select
              </el-button> -->
              <router-link to="/email-signup?role=Employer" tag="button" type="text" class="el-button button">Sign up</router-link>
            </div>
          </el-card>
        </div>
        <div class="mb-4 col-lg-3 col-md-6 col-sm-6 col-xs-12 employer-silver">
          <el-card class="pricing-card silver" body-style="padding: 30px 0 0 0;">
            <!-- <h5 class="ribbon">
              75% off!
            </h5>   -->
            <h5 class="text-center">Employer</h5>
            <h5 class="mb-4 text-center title">Silver</h5>
            <div class="price-banner">
              <h3 class="m-0" style="line-height: 1.3;"><span class="small gray strikethrough">${{ prices[frequency].silver }}/month</span>
                FREE <br>
                <!-- <span class="small gray">{{ frequency }}</span>
                 -->
                 <span class="small gray">for your first <span class="orange-text" style="font-weight: 600;">3 months</span></span></h3>
              <!-- <h3 class="m-0">${{ prices[frequency].silver }} <span class="small gray">{{ frequency }}</span></h3> -->
            </div>
            <ul>
              <li>Up to 3 job posts</li>
              <li>Instant approval</li>
              <li>Bookmark freelancers</li>
              <li>Contact 50 freelancers per month</li>
              <li class="mb-4">View reviews</li>
            </ul>
            <!-- <div class="offer-banner">
              <p class="mb-0 text-center small"><span class="bold">75% off</span> to the next <br>500 employers!</p>  
            </div> -->
            <div class="clearfix bottom">
              <!-- <el-button type="text" class="button"
                         @click="loginModal = true;
                         selected = 'silver'">Select
              </el-button> -->
              <router-link to="/email-signup?role=Employer" tag="button" type="text" class="el-button button">Sign up</router-link>
            </div>
          </el-card>
        </div>
        <div class="mb-4 col-lg-3 col-md-6 col-sm-6 col-xs-12 employer-concierge">
          <el-card class="pricing-card" body-style="padding-top: 30px; padding-right:0; padding-left: 0;">
            <h5 class="text-center">Employer</h5>
            <h5 class="mb-4 text-center title">Concierge</h5>
            <div class="price-banner">
              <h3 class="m-0" style="line-height: 1.3;">
                $599
                <span class="small gray">Entry Level</span>
                <!-- ${{ prices[frequency].gold }}  -->
                <!-- <span class="small gray">{{ frequency }}</span> -->
                <br>
               $999  <span class="small gray">Regular Roles</span>
              </h3>
            </div>
            <ul>
              <!-- <li>Unlimited job posts*</li>
              <li>Instant approval</li>
              <li>Bookmark freelancers</li>
              <li>Contact unlimited freelancers*</li>
              <li>View reviews</li>
              <p class="mt-3 small gray-text">*Subject to fair use policy</p> -->
              <li>Your CitronWorks Concierge will conduct the search and supply you with the top candidates who meet your requirements.</li>
 
              <li>You contact and interview the shortlisted candidates, and hire the one you like the most.</li>
 
              <li>No commission and full money-back guarantee.</li>
    
            </ul>

            <div class="clearfix bottom">
              <router-link to="/concierge" tag="button" type="text" class="el-button button">Learn more</router-link>
            </div>
          </el-card>
        </div>
        <el-dialog
          :visible.sync="loginModal"
          width="40%"
          center>
          <span slot="title" class="dialog-title">
            <h4 class="orange-text bold">Signup or Login</h4>
          </span>
          <span>
            <a
                    :href="'/employer/signup?plan=' +
                    selected">
              <el-button
                    type="warning" class="mb-3 w-100" @click="dialogVisible = false">Sign up to start hiring!
              </el-button>
            </a>
          </span>
          <span>
            <a href="/login"><el-button type="warning"
                                        plain class="w-100" @click="dialogVisible = false"><span style="word-break: break-word; white-space: normal; line-height: 1.4;">Already have an account?<br class="visible-xs">Click here to login.</span></el-button></a>
          </span>
        </el-dialog>
      </div>
      <center>
        <img src="images/green-divider.png" width="90" height="auto" class="mt-5 mb-5 divider"  id="freelancers">
        <h3 class="mb-5 orange-text">We are always <span style="font-weight: 600;">FREE</span> for freelancers!</h3>
      </center>



      <div class="mt-4 row justify-content-center">
        <div class="mb-4 col-lg-8 col-md-10 col-sm-6 col-xs-12 free hidden-xs">
          <el-card class="pricing-card" body-style="" >
            <el-row type="flex" class="align-items-center">
              <el-col :md="6" :xs="24">
                <h5 class="">Freelancer</h5>
                <h5 class="title">Account</h5>
                <div class="price-banner visible-xs">
                  <h3 class="m-0">Free</h3>
                </div>
              </el-col>
              <el-col :md="13" :xs="24">
                <ul class="freelancer">
                  <li>Search and apply for jobs</li>
                  <li>Message employers that contact you</li>
                  <li class="mb-4">Display your professional profile</li>
                </ul>    
              </el-col>
              <el-col :md="5" class="text-center" :xs="24">
                <h3 class="green-text">FREE!</h3>
                <router-link to="/freelancersignup" tag="button" type="text" class="el-button button freelancer el-button--success">Signup now!</router-link>
              </el-col>
            </el-row>
          </el-card>
        </div>
        <div class="mb-4 col-lg-3 col-md-6 col-sm-6 col-xs-12 employer-trial visible-xs">
          <el-card class="pricing-card" body-style="padding: 30px 0 0 0;" >
            <h5 class="text-center">Freelancer</h5>
                <h5 class="text-center title">Account</h5>
                <div class="price-banner">
                  <h3 class="m-0 green-text">Free</h3>
                </div>
            <ul class="">
              <li>Search and apply for jobs</li>
              <li>Message employers that contact you</li>
              <li class="mb-4">Display your professional profile</li>
            </ul>    
            <div class="clearfix bottom">
              <router-link tag="button" class="el-button button el-button--success"
                         to="/freelancersignup">Sign up now!
              </router-link>
            </div>
          </el-card>
        </div>
      </div>

    </div>
  </div>
</div>
</template>

<script>
  export default {
    data () {
      return {
        loginModal: false,
        frequency: 'monthly',
        selected: '',
        prices: {
          monthly: {
            silver: 69,
            gold: 99,
            slashed: 17.25,
          },
          yearly: {
            silver: 690,
            gold: 990,
            slashed: 172.50,
          },
        },
      }
    }
  };
</script>

<style lang="scss" scoped>
.ribbon {
  margin: 0;
  padding: 0;
  background: #E87722;
  color:white;
  padding:.5em 1em;
  position: absolute;
  top: -10px;
  right: 0;
  transform: translateX(30%) translateY(0%) rotate(40deg);
  transform-origin: top left;
  font-weight: bold;
  letter-spacing: 1px;
  font-size: 1rem !important;
  text-transform: lowercase !important;
}

.ribbon:before,
.ribbon:after {
  content: '';
  position: absolute;
  top:0;
  margin: 0 -1px; /* tweak */
  width: 100%;
  height: 100%;
  background: #E87722;
}
.ribbon:before {
  right:100%;
}

.ribbon:after {
  left:100%;
}
  .banner{
    padding: 55px 20px;
    text-align: center;
    min-height: 170px;

    p {
      max-width: 500px;
      margin: 0 auto;
    }
    h3, p {
      color: white;
    }
    
    @media (max-width: 575px) {
      
    }
  }
  .pricing {
    position: relative;
    padding: 50px 0;
    background-image: url('/images/gray-pricing-bg.png'); 
    background-size: contain;
    background-repeat: no-repeat;
    box-shadow: 0 1px 5px 1px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }

  #app .pricing {
    h6 {
      font-size: 1.1rem !important;
    }
    .offer {
      background: #E87722;
      color:white;
      padding: 1rem 15px 1rem 15px;
      // width: 100%;
      width: fit-content;
      // border-radius: 5px 5px 0 0;
      border-radius: 5px;

      p {
        margin-bottom: 0;
      }
    }
    .toggle-btns.price ul {
      margin-bottom: 40px !important;
    }
    .toggle-btns.price ul.tab li label {
      padding: 5px;
      width: 100px;
      font-size: 14px;
      border: 1px solid #f79524;;
      color: #f79524;
      font-weight: bold;
      border-radius: 5px;
    }
    .toggle-btns ul.tab li {
      display: inline;  
    }
    .toggle-btns.price ul.tab li input[type=radio]:checked ~ label {
      color: #FFF;
      background-color: #91C145;
      border-color: #91C145;
    }
    .price-banner {
      padding: 15px 0;
      border-bottom: 1px solid rgba(0,0,0,.1);
      border-top: 1px solid rgba(0,0,0,.1);
      margin: 30px 0 15px 0;
      -webkit-transition: all .2s ease-in-out;
      -moz-transition: all .2s ease-in-out;
      -ms-transition: all .2s ease-in-out;
      -o-transition: all .2s ease-in-out;

    }
    .employer-silver .price-banner {
      // margin-top: 0px;
    }
    .employer-silver .offer-banner {
      padding: 10px;
      background: #E87722;
      color:white;
      width: 80%;
      margin: 0 auto 70px;
      border-radius: 5px;
    }
    .price-banner.more-padding {
      margin-top: 40px;
    }
    .price-banner.sale {
      margin-top: 0px;
    }
    .pricing-card{
      height: 100%;
      border: 0;
      position: relative;
      -webkit-transition: all .2s ease-in-out;
      -moz-transition: all .2s ease-in-out;
      -ms-transition: all .2s ease-in-out;
      -o-transition: all .2s ease-in-out;
      border-radius: 5px !important;

      .title {
        font-weight: 300;
        font-size: 1.4rem !important;
      }
    }
    h5 {
      text-transform: uppercase;
      // letter-spacing: .6px;
      font-weight: bold;
      font-size: 1.1rem;
    }
    .pricing-card h3{
      text-transform: uppercase;
      letter-spacing: .6px; 
      text-align: center;
      font-weight: bold;
      color: #f79524;
    }
    .pricing-card h3 .small{
      text-transform: lowercase;
      font-size: 16px;
      color: gray;
    }
    .pricing-card ul {
      padding-top: 5px;
      padding-left:25px;
      padding-right:30px;
      padding-bottom: 30px !important;
    }
    .employer-silver .pricing-card ul {
      // padding-bottom: 0 !important;
    }
    ul li{
      padding: 5px 0;
      list-style: none;
    }
   .toggle-btns.price ul.tab li {
     display: inline;
    }
    .pricing-card li {
      margin-left: 1.3em;
      font-size: 14px;
    }
    .pricing-card li:before {
      content: "\e79c";
      font-family: element-icons!important;
      display: inline-block;
      margin-left: -1.3em; /* same as padding-left set on li */
      width: 1.5em; /* same as padding-left set on li */
      color: #f79524;
    }
    .pricing-card .freelancer li:before {
      color: #91C145;
      margin-left: -2em;
    }
    .pricing-card ul.freelancer {
      padding: 0 !important;
      padding-bottom: 0 !important;
      margin-bottom: 0;
    }
    .pricing-card ul.freelancer li {
      letter-spacing: .8px;
      margin-left: 2em;
    }
    .pricing-card ul.freelancer li:last-child {
      margin-bottom: 5px !important;
    }
    .el-button--success {
      // margin-left: auto;
      // display: block;
    }
    .free h5 {
      letter-spacing: 1px;
    }
    .bottom {
      position: absolute;
      width: 100%;
      bottom: 0;
    }
    .bottom .button {
      font-size: 18px;
      font-weight: bold;
      color: white;
      border: none;
      margin: 0;
      width: 100%;
      background: orange;
      border-radius: 0;
      -webkit-transition: all .2s ease-in-out;
      -moz-transition: all .2s ease-in-out;
      -ms-transition: all .2s ease-in-out;
      -o-transition: all .2s ease-in-out;
      cursor: pointer;
    }
    .bottom .button.freelancer {
      background: #91C145;
    }
    .bottom .button.freelancer:hover {
      background: #84b931;
    }
    .pricing .pricing-card li[data-v-9ef070e8]:before
    .bottom .button.gray {
      background: lightgray;
      cursor: default;
    }
    .bottom .button:active, .bottom .button:focus {
      outline: none;
    }
    .el-card.is-always-shadow.pricing-card:hover {
      -webkit-box-shadow: #666666 0 3px 5px 0 !important;
      box-shadow: #666666 0 3px 5px 0 !important;
      
      .price-banner {
        background: #91C145;
      }
      .price-banner h3 {
        color: white !important;
      }
      .price-banner h3 .gray, .price-banner h3 .gray .orange-text{
        color: white !important;
      }
      .bottom .button {
        background: #f79524;
      }
      .bottom .button.gray {
        background: lightgray;
      }
      .bottom .button.freelancer {
        background: #84b931;
      }
    } 
  }
  .citronworkers {
    padding-top: 15px;
    padding-bottom: 15px;
    background: rgb(253 203 25 / .1);
    border: 1px solid #fdcb19;
    border-radius: 5px;
  }
  @media (min-width: 993px) {
    .offer {
      // margin-top: -30px;
      box-shadow: #979797 0 2px 4px 0;
    }    
    .pricing .offer-card{
      // border-radius: 0 0 5px 5px !important;
      border-radius: 5px;
    }
    .free {
      padding-top: 15px;
      padding-bottom: 15px;
      background: #f9f9f7;
      border: 1px solid lightgray;
      border-radius: 5px;
    }
    .pricing-card.silver {
      // height: calc(100% - 40px);
    }
    .employer-trial {
      padding-top: 15px;
      padding-bottom: 15px;
      background: rgb(145 193 69 / 25%);
      border-radius: 5px 0 0 5px;
      border-left: 1px solid rgb(145,193,69);
      border-top: 1px solid rgb(145,193,69);
      border-bottom: 1px solid rgb(145,193,69);
    }
    .citronworkers {
      margin-left: 15px;
    }
    .employer-silver {
      padding: 15px 0 15px 0;
      background: rgb(145 193 69 / 25%);
      max-width: 23%;
      border-top: 1px solid rgb(145,193,69);
      border-bottom: 1px solid rgb(145,193,69);
    }
    // .employer-concierge {
    //   padding: 15px 0 15px 7.5px;
    //   background: rgb(145 193 69 / 25%);
    //   max-width: 23%;
    //   border-top: 1px solid rgb(145,193,69);
    //   border-bottom: 1px solid rgb(145,193,69);
    // }
    .employer-concierge {
      padding-top: 15px;
      padding-bottom: 15px;
      background: rgb(145 193 69 / 25%);
      border-radius: 0 5px 5px 0;
      border-right: 1px solid rgb(145,193,69);
      border-top: 1px solid rgb(145,193,69);
      border-bottom: 1px solid rgb(145,193,69);
    }
  }
  @media (max-width: 992px) {
    .free {
      padding: 15px;
      background: #f9f9f7;
      border: 1px solid lightgray;
      border-radius: 5px;
      margin: 0 15px;
    }
    .employer-trial {
      padding: 15px;
      background: rgb(145 193 69 / 25%);
      border-radius: 5px;
      border: 1px solid rgb(145,193,69);
      margin: 0 15px;
    }
    .employer-silver {
      padding: 15px;
      background: rgb(145 193 69 / 25%);
      border: 1px solid rgb(145,193,69);
      border-radius: 5px;
      margin: 0 15px;
    }
    .employer-concierge {
      padding: 15px;
      background: rgb(145 193 69 / 25%);
      border: 1px solid rgb(145,193,69);
      border-radius: 5px;
      margin: 0 15px;
    }
    .employer-citronworkers {
      padding: 15px;
      background: rgb(145 193 69 / 25%);
      border: 1px solid rgb(145,193,69);
      border-radius: 5px;
      margin: 0 15px;
    }
    .pricing {
      .toggle-btns.price ul {
        margin-bottom: 15px !important;
      }

      .offer {
        width: 100%;
      }
      .pricing-card {
        height: auto;
      }
      .price-banner.more-padding {
        margin-top: 30px;
      }
    }
  }
  @media (max-width: 1199px) and (min-width: 993px) {
    .col-md-6 {
      -ms-flex: 0 0 50%;
      flex: 0 0 50% !important;
      max-width: 50% !important;
    }
    .employer-silver {
      padding: 15px 15px 15px 0;
      background: rgb(145 193 69 / 25%);
      max-width: 23%;
      border-top: 1px solid rgb(145,193,69);
      border-bottom: 1px solid rgb(145,193,69);
      border-right: 1px solid rgb(145,193,69);
      border-radius: 0 5px 5px 0;
    }
    .employer-concierge {
      padding: 15px;
      background: rgb(145 193 69 / 25%);
      max-width: 23%;
      border-top: 1px solid rgb(145,193,69);
      border-bottom: 1px solid rgb(145,193,69);
      border-left: 1px solid rgb(145,193,69);
      border-radius: 5px;
    }
    .citronworkers,  .employer-concierge{
      max-width: 49% !important;
    }
    .employer-citronworkers{
      padding-left: 0;
    }
    .pricing-card.silver {
      // height: calc(100% - 22px);
    }
  }
  @media (max-width: 691px) {
    .free .d-xs-block {
      display: block;

      h5:first-child {
        text-align: center;
        margin-top:10px;
      }
      h5:last-child {
        text-align: center;
        margin-bottom:30px;
      }
    }
  }
  @media (max-width: 767px) {
    .pricing {
      padding: 26px 0;
    }
    h3 {
      font-size: 1.5rem;
      // margin-bottom: 1.5rem !important;
    }
    .divider {
      margin-top: 1rem !important;
      margin-bottom: 1.5rem !important;
    }
    h1 {
      font-size: 1.75rem;
    }
    .pricing h6 {
      font-size: .9rem !important; 
      margin-bottom: 10px !important;
    }
    .citronworkers {
      margin: 0 15px 15px 15px;
    }
  }
</style>
